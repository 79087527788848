import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ForgetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index.js"));
const Studio = React.lazy(() => import("./pages/Core/Studio/Index.js"));
const OfferDetail = React.lazy(() => import("./pages/Core/Offer/Index.js"));
const AboutUs = React.lazy(() => import("./pages/Core/AboutUS/Index.js"));
const Contact = React.lazy(() => import("./pages/Core/Contact/Index.js"));
const KarolPage = React.lazy(() => import("./pages/Core/KarolPage/Index.js"));
const SearchResults = React.lazy(() =>
  import("./pages/Core/SearchResults/Index.js")
);
const SellerPage = React.lazy(() => import("./pages/Core/SellerPage/Index.js"));
const Category = React.lazy(() => import("./pages/Core/Category/Index.js"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/Core/PrivacyPolicy/Index.js")
);
const Regulations = React.lazy(() =>
  import("./pages/Core/Regulations/Index.js")
);

// USER
const AddOffer = React.lazy(() => import("./pages/User/AddOffer/Index.js"));
const AddOfferThankYou = React.lazy(() =>
  import("./pages/User/AddOfferThankYou/Index.js")
);
const Chat = React.lazy(() => import("./pages/User/Chat/Index.js"));
const Offers = React.lazy(() => import("./pages/User/Offers/Index.js"));
const Profile = React.lazy(() => import("./pages/User/Profile/Index.js"));
const UpdateOffer = React.lazy(() =>
  import("./pages/User/UpdateOffer/Index.js")
);

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route path="/" element={<Home />} />
          <Route path="/studio" element={<Studio />} />
          <Route path="/oferty" element={<SearchResults />} />
          <Route path="/kategorie/:cat_id" element={<Category />} />
          <Route path="/kategorie/:cat_id/:sub_cat_id" element={<Category />} />
          <Route
            path="/kategorie/:cat_id/:sub_cat_id/:final_cat_id"
            element={<Category />}
          />
          <Route
            path="/oferta/:offer_id/:offer_slug"
            element={<OfferDetail />}
          />
          <Route path="/sprzedawca/:seller_id" element={<SellerPage />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/karol" element={<KarolPage />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
          <Route path="/regulamin" element={<Regulations />} />

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/resetuj-haslo" element={<ForgetPassword />} />

          {/* USER */}
          <Route path="/moje-konto/dodaj-ogloszenie/" element={<AddOffer />} />
          <Route
            path="/moje-konto/dziekujemy/"
            element={<AddOfferThankYou />}
          />
          <Route path="/moje-konto/edytuj-profil/" element={<Profile />} />
          <Route path="/moje-konto/panel/" element={<Offers />} />
          <Route path="/moje-konto/wiadomosci/" element={<Chat />} />
          <Route
            path="/moje-konto/edytuj/:id/:slug"
            element={<UpdateOffer />}
          />

          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
