import { SettingsURL } from "../settings";

export async function signIn(email, password) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };
    const response = await fetch(
      SettingsURL + "/api/auth/login/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zalogować",
    };
  }
}

export async function forgetPassword(data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      SettingsURL + "/api/auth/forget-password/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zarejestrować",
    };
  }
}

export async function SignUp(data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      SettingsURL + "/api/auth/signup/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zarejestrować",
    };
  }
}

export async function getMyData(token) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(SettingsURL + "/api/auth/me/", requestOptions);
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zalogować",
    };
  }
}
